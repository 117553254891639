<template>
  <div
    v-if="proposta && proposta.segurado && proposta.segurado.tomador"
    class="tw-w-full tw-min-h-screen tw-flex tw-justify-center tw-items-center"
  >
    <section class="tw-bg-white tw-rounded-3xl tw-p-8">
      <div v-if="!valido">
        <header>
          <h4>Aceite de proposta, termos e condições</h4>
          <div class="tw-bg-[#44845C] tw-text-white tw-p-4 tw-rounded-lg">
            <h5 class="tw-text-white">{{ proposta.segurado.tomador.nomeCompleto }}</h5>
            <div class="tw-flex tw-justify-between">
              <span>CPF:{{ proposta.segurado.tomador.cpf }}</span>
              <span>Matrícula: {{ proposta.segurado.matricula }}</span>
            </div>
            <span>Proposta: {{ formatarCodigoSequencial(proposta.codigo) }}</span>
          </div>
        </header>
        <div>
          <h5 class="tw-my-2">Detalhes da proposta {{ produto.termos.habilitaTermoECondicaoTitulo }}</h5>
          <div>
            <div class="tw-flex tw-justify-between">
              <b-form-group label="Valor da proposta" label-for="proposta.valorTotalComJuros">
                <b-form-input id="proposta.valorTotalComJuros" :value="proposta.valorTotalComJuros" :disabled="true" />
              </b-form-group>
              <b-form-group label="Parcelas" label-for="proposta.parcelas">
                <b-form-input id="proposta.parcelas" :value="proposta.parcelas" :disabled="true" />
              </b-form-group>
            </div>
            <div class="tw-flex tw-justify-between">
              <b-form-group label="Valor da parcela" label-for="proposta.valorPrimeiraParcela">
                <b-form-input id="proposta.valorPrimeiraParcela" :value="proposta.valorPrimeiraParcela" :disabled="true" />
              </b-form-group>
              <b-form-group label="Taxa de juros" label-for="proposta.taxa">
                <b-form-input id="proposta.taxa" :value="proposta.taxa" :disabled="true" />
              </b-form-group>
            </div>
          </div>
          <div class="tw-flex tw-flex-col tw-gap-3" ref="formTermo">
            <b-col md="auto" class="tw-flex tw-items-center tw-gap-2" v-if="produto.termos.habilitaTermoECondicao">
              <validation-provider #default="{ errors }" name="Habilita termos e condições">
                <b-form-checkbox
                  class="custom-control-primary tw-m-0"
                  name="habilitaTermoECondicao"
                  id="habilitaTermoECondicao"
                  switch
                  v-model="termos.habilitaTermoECondicao"
                  inline
                >
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <p class="tw-mb-0">
                Li e estou de acordo com
                <span
                  @click="
                    $refs['modal-termo'].show(),
                      (termoSelecionado = {
                        tipo: 'habilitaTermoECondicao',
                        titulo: produto.termos.habilitaTermoECondicaoTitulo,
                        texto: produto.termos.habilitaTermoECondicaoTexto,
                      })
                  "
                  class="tw-text-[#44845C] tw-font-bold tw-cursor-pointer hover:tw-font-extrabold"
                  >{{ produto.termos.habilitaTermoECondicaoTitulo }}</span
                >
              </p>
            </b-col>

            <b-col md="auto" class="tw-flex tw-items-center tw-gap-2" v-if="produto.termos.habilitaTermoECondicaoProposta">
              <validation-provider #default="{ errors }" name="Habilita termos e condições">
                <b-form-checkbox
                  class="custom-control-primary tw-m-0"
                  name="habilitaTermoECondicaoProposta"
                  id="habilitaTermoECondicaoProposta"
                  switch
                  v-model="termos.habilitaTermoECondicaoProposta"
                  inline
                >
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <p class="tw-mb-0">
                Li e estou de acordo com
                <span
                  @click="
                    $refs['modal-termo'].show(),
                      (termoSelecionado = {
                        tipo: 'habilitaTermoECondicaoProposta',
                        titulo: produto.termos.habilitaTermoECondicaoPropostaTitulo,
                        texto: produto.termos.habilitaTermoECondicaoPropostaTexto,
                      })
                  "
                  class="tw-text-[#44845C] tw-font-bold tw-cursor-pointer hover:tw-font-extrabold"
                  >{{ produto.termos.habilitaTermoECondicaoPropostaTitulo }}</span
                >
              </p>
            </b-col>

            <b-col md="auto" class="tw-flex tw-items-center tw-gap-2" v-if="produto.termos.habilitaAceiteDeMargem">
              <validation-provider #default="{ errors }" name="Habilita termos e condições">
                <b-form-checkbox
                  class="custom-control-primary tw-m-0"
                  name="habilitaAceiteDeMargem"
                  id="habilitaAceiteDeMargem"
                  switch
                  v-model="termos.habilitaAceiteDeMargem"
                  inline
                >
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <p class="tw-mb-0">
                Li e estou de acordo com
                <span
                  @click="
                    $refs['modal-termo'].show(),
                      (termoSelecionado = {
                        tipo: 'habilitaAceiteDeMargem',
                        titulo: produto.termos.habilitaAceiteDeMargemTitulo,
                        texto: produto.termos.habilitaAceiteDeMargemTexto,
                      })
                  "
                  class="tw-text-[#44845C] tw-font-bold tw-cursor-pointer hover:tw-font-extrabold"
                  >{{ produto.termos.habilitaAceiteDeMargemTitulo }}</span
                >
              </p>
            </b-col>

            <b-col md="auto" class="tw-flex tw-items-center tw-gap-2" v-if="produto.termos.habilitaPoliticaDePrivacidade">
              <validation-provider #default="{ errors }" name="Habilita termos e condições">
                <b-form-checkbox
                  class="custom-control-primary tw-m-0"
                  name="habilitaPoliticaDePrivacidade"
                  id="habilitaPoliticaDePrivacidade"
                  switch
                  v-model="termos.habilitaPoliticaDePrivacidade"
                  inline
                >
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <p class="tw-mb-0">
                Li e estou de acordo com
                <span
                  @click="
                    $refs['modal-termo'].show(),
                      (termoSelecionado = {
                        tipo: 'habilitaPoliticaDePrivacidade',
                        titulo: produto.termos.habilitaPoliticaDePrivacidadeTitulo,
                        texto: produto.termos.habilitaPoliticaDePrivacidadeTexto,
                      })
                  "
                  class="tw-text-[#44845C] tw-font-bold tw-cursor-pointer hover:tw-font-extrabold"
                  >{{ produto.termos.habilitaPoliticaDePrivacidadeTitulo }}</span
                >
              </p>
            </b-col>
            <b-col class="tw-flex tw-justify-end tw-gap-2">
              <b-button variant="primary" :disabled="!termosAceitos" @click="concluirAceitarTodosTermos">Validar</b-button>
            </b-col>
          </div>
        </div>
        <b-modal
          ref="modal-termo"
          centered
          size="sm"
          title="Aceitar o termo"
          no-close-on-backdrop
          no-close-on-esc
          ok-title="Aceito"
          ok-only
          @ok="aceitarTermo(termoSelecionado.tipo)"
          :ok-disabled="!scrolledToBottom"
          @shown="iniciarVerificacaoScroll"
          class="tw-max-h-[50vh]"
        >
          <div class="tw-max-h-[40vh] tw-overflow-y-auto tw-overflow-x-auto" @scroll="verificarRolagem" ref="modalContent">
            <h3>{{ termoSelecionado.titulo }}</h3>
            <p v-html="termoSelecionado.texto"></p>
          </div>
        </b-modal>
      </div>
      <div v-else class="tw-text-[#44845C]">
        <div v-if="isLoading" class="tw-flex tw-flex-col tw-items-center tw-gap-10 tw-p-10">
          <b-spinner style="width: 3rem; height: 3rem" />
          <h1 class="tw-text-[#44845C]">Carregando...</h1>
        </div>
        <div v-else class="tw-flex tw-flex-col tw-items-center tw-gap-10 tw-p-10">
          <feather-icon icon="CheckCircleIcon" size="60" />
          <h1 class="tw-text-[#44845C]">Concluído</h1>
        </div>
      </div>
    </section>
  </div>
  <div v-else class="tw-w-full tw-min-h-screen tw-flex tw-justify-center tw-items-center">
    <p>Carregando proposta...</p>
  </div>
</template>
<script>
  import useJwt from '@/auth/jwt/useJwt'
  import { detectarNavegador, formatarCodigoSequencial, formatarValorPorcentagem, formatarValorReais } from '@/libs/utils'
  import { ValidationProvider } from 'vee-validate'
  import { VueEditor } from 'vue2-editor'
  export default {
    components: {
      formatarValorReais,
      ValidationProvider,
      VueEditor,
    },
    data() {
      return {
        proposta: {},
        formatarCodigoSequencial,
        produto: {
          termos: { habilitaTermoECondicaoTitulo: '' },
        },
        termos: {
          habilitaTermoECondicao: false,
          habilitaTermoECondicaoProposta: false,
          habilitaAceiteDeMargem: false,
          habilitaPoliticaDePrivacidade: false,
        },

        termoSelecionado: {},
        valido: false,
        isLoading: false,
        scrolledToBottom: false,
      }
    },
    computed: {
      termosValidos() {
        return Object.keys(this.produto.termos).filter((key) => this.produto.termos[key] === true)
      },
      termosAceitos() {
        return this.termosValidos.every((key) => this.termos[key] === true)
      },
    },
    mounted() {
      const tomador = this.$route.query.dataparam
      const codigo = this.$route.query.datacode
      this.carregarTomador(tomador, codigo)
    },
    methods: {
      async carregarTomador(tomador, codigo) {
        const propostaResponse = await useJwt.get(`proposta/GetPesquisarPropostaTomador/${codigo}/${tomador}`)
        this.proposta = {
          ...propostaResponse.data,
          valorTotalComJuros: formatarValorReais(propostaResponse.data.valorTotalComJuros),
          valorPrimeiraParcela: formatarValorReais(propostaResponse.data.valorPrimeiraParcela),
          taxa: formatarValorPorcentagem(propostaResponse.data.taxa),
        }

        this.carregarTermos()
      },
      async carregarTermos() {
        const termosResponse = await useJwt.get(`produto/get-termos/${this.proposta.produtoId}`)
        this.produto.termos = termosResponse.data
      },
      aceitarTermo(tipo) {
        if (this.termos.hasOwnProperty(tipo)) {
          this.termos[tipo] = true
        }

        this.$refs['modal-termo'].hide()
      },
      async concluirAceitarTodosTermos() {
        this.valido = true
        this.isLoading = true
        const { localizacao, ip } = await this.getLocalizacao()

        const data = {
          ip: ip,
          navegadorUtilizado: detectarNavegador(),
          localizacao: localizacao.cidade,
          propostaId: this.proposta.id,
        }

        await useJwt.put('produto/termo/propostaAlterarTermos', data)
        this.isLoading = false
      },

      async getLocalizacao() {
        const ip = await fetch('https://api.ipify.org?format=json')
          .then((response) => response.json())
          .then((data) => data.ip)
          .catch(() => 'IP não disponível')

        const localizacao = await fetch(`https://reallyfreegeoip.org/json/${ip}`)
          .then((response) => response.json())
          .then((data) => ({
            cidade: data.city || 'Cidade não disponível',
            pais: data.country_name || 'País não disponível',
            latitude: data.latitude || 'Latitude não disponível',
            longitude: data.longitude || 'Longitude não disponível',
          }))
          .catch(() => 'Localização não disponível')

        return { localizacao, ip }
      },
      iniciarVerificacaoScroll() {
        this.scrolledToBottom = false
        this.$nextTick(() => {
          const modalContent = this.$refs.modalContent

          if (modalContent) {
            if (modalContent.scrollHeight > modalContent.clientHeight) {
              modalContent.addEventListener('scroll', this.verificarRolagem)
            } else {
              this.scrolledToBottom = true
            }
          }
        })
      },
      verificarRolagem() {
        const modalContent = this.$refs.modalContent

        if (modalContent) {
          if (modalContent.scrollTop + modalContent.clientHeight >= modalContent.scrollHeight - 30) {
            this.scrolledToBottom = true
          } else {
            this.scrolledToBottom = false
          }
        }
      },
    },
  }
</script>
<style scoped></style>
